import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import Wrapper from "./Wrapper";
import "./images.css";
import "./forms.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export function CarouselComponent() {
    /*input fields*/
    var txtButton = "";
    const [cb1, setCB1] = useState(false)
    const [cb2, setCB2] = useState(false)
    const [cb3, setCB3] = useState(false)
    const [cb4, setCB4] = useState(false)
    const [agree, setAgree] = useState(false)
    
    const [amph, setAMPH] = useState();                                        //D8
    const [avmh, setAVMH] = useState();                                        //D9
    const [broadcastImpressions, setBroadcastImpressions] = useState();        //D13
    const [ddlImpressions, setDDLImpressions] = useState();                    //D14
    const [addressableImpressions, setAddressableImpressions] = useState();    //D15
    const [digitalImpressions, setDigitalImpressions] = useState();            //D16
    const [broadcastRevenue, setBroadcastRevenue] = useState(0);                //D27
    const [ddlRevenue, setDDLRevenue] = useState(0);                            //D28
    const [addressableRevenue, setAddressableRevenue] = useState(0);            //D29
    const [drRevenue, setDRRevenue] = useState(0);                              //D30
    const [digitalRevenue, setDigitalRevenue] = useState(0);                    //D31
    const [numMapChannels, setNumMapChannels] = useState();                    //D42
    const [numStations, setNumStations] = useState();                    
    const [totalSalary, setTotalSalary] = useState();                          //D43
    const [digitaleCPMOverride, setDigOverride] = useState();//D23
    const [digitalPGRcurrent, setdigitalPGRcurrent] = useState(0);
    const [digitalPGRold, setdigitalPGRold] = useState(0);
    const [digitaleCPMYear1, setdigitaleCPMYear1] = useState(0);
    const [digitaleCPMYear2, setdigitaleCPMYear2] = useState(0);
    const [digitaleCPMYear3, setdigitaleCPMYear3] = useState(0);
    const [digitaleCPMYear4, setdigitaleCPMYear4] = useState(0);
    const [digitaleCPMYear5, setdigitaleCPMYear5] = useState(0);
    const [digitalrevenueyear1,setdigitalrevenueyear1] = useState(0);
    const [digitalrevenueyear2,setdigitalrevenueyear2] = useState(0);
    const [digitalrevenueyear3,setdigitalrevenueyear3] = useState(0);
    const [digitalrevenueyear4,setdigitalrevenueyear4] = useState(0);
    const [digitalrevenueyear5,setdigitalrevenueyear5] = useState(0);

    /*hardcoded inputs*/
    const increaseInRevFromEffectivePricing = .17                                   //D68
    const increaseIneCPM = .35                                                      //D69
    const ecpmYear1 = (1 + (increaseInRevFromEffectivePricing + increaseIneCPM)/5)
    const ecpmYear2 = (1 + (increaseInRevFromEffectivePricing + increaseIneCPM)/4)
    const ecpmYear3 = (1 + (increaseInRevFromEffectivePricing + increaseIneCPM)/3)
    const ecpmYear4 = (1 + (increaseInRevFromEffectivePricing + increaseIneCPM)/2)
    const ecpmYear5 = (1 + (increaseInRevFromEffectivePricing + increaseIneCPM)/1)

    /*contact info input */
    const [contactEmail, setContactEmail] = useState('');
    const [isdisabled, setIsDisabled] = useState(true) // For nav button
    const [contactFirstName, setContactFirstName] = useState('');
    const [contactLastName, setContactLastName] = useState('');
    const [contactCompany, setContactCompany] = useState('');
    const [contactPosition, setContactPosition] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [isBtnDisabled, setisBtnDisabled] = useState(true);

    /*check to ensure valid email address entered*/
    function isValidEmail(email) {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
      }
    
    /*user is entering an email address*/
    const onEmailChange = (e) => {
        setContactEmail((prevState) => (e.target.value));
        if(e.target.value.trim().length < 1) {   // Checking the length of the input
            setIsDisabled(true);  // Disabling the button if length is < 1
        } else {
            //check for valid format
            if(isValidEmail(e.target.value)) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        }
    }
   
    /*perform the calculations*/
    const impressionsbroadcast =    ((amph/60) * (avmh/60) * amph * 2 * 24 * broadcastImpressions) * 365.25 || 0;   //D20
    const impressionsddl =          ((amph/60) * (avmh/60) * amph * 2 * 24 * ddlImpressions) * 365.25 || 0;               //D21
    const impressionsaddressable =  ((amph/60) * (avmh/60) * avmh * 2 * 24 * addressableImpressions) * 365.25 || 0; //D22
    const impressionsdigital =      ((amph/60) * (avmh/60) * avmh * 2 * 24 * digitalImpressions) * 365.25 || 0; 
    
    //eCPM
    const bcastPGRcurrent = (broadcastRevenue * 1000) / impressionsbroadcast ||0             //D35
    const ddlPGRcurrent = (ddlRevenue * 1000) / impressionsddl ||0                           //D36
    const addressablePGRcurrent=(addressableRevenue *1000 ) / impressionsaddressable ||0     //D37
    const drPGRcurrent=(drRevenue * 1000) / impressionsbroadcast ||0                         //D38

    /*user is entering a current digital revenue */
    const onDigRevChange = (e) => {
        var PGRCurrent = (e.target.value * 1000 / impressionsdigital)||0;
        var eCPMYear1 = PGRCurrent * ecpmYear1;
        var eCPMYear2 = PGRCurrent * ecpmYear2;
        var eCPMYear3 = PGRCurrent * ecpmYear3;
        var eCPMYear4 = PGRCurrent * ecpmYear4;
        var eCPMYear5 = PGRCurrent * ecpmYear5;
        var revenue = e.target.value;

        setDigOverride(0);
        setDigitalRevenue(revenue);
        setdigitalPGRcurrent(PGRCurrent);//(digitalRevenue * 1000) / impressionsdigital);
        setdigitalPGRold(PGRCurrent); //same as ^
        setdigitaleCPMYear1(eCPMYear1||0);
        setdigitaleCPMYear2(eCPMYear2||0);
        setdigitaleCPMYear3(eCPMYear3||0);
        setdigitaleCPMYear4(eCPMYear4||0);
        setdigitaleCPMYear5(eCPMYear5||0);
        setdigitalrevenueyear1((eCPMYear1/PGRCurrent * revenue)||0);
        setdigitalrevenueyear2((eCPMYear2/PGRCurrent * revenue)||0);
        setdigitalrevenueyear3((eCPMYear3/PGRCurrent * revenue)||0);
        setdigitalrevenueyear4((eCPMYear4/PGRCurrent * revenue)||0);
        setdigitalrevenueyear5((eCPMYear5/PGRCurrent * revenue)||0);
        // }
    }

    /*user is entering a digital override*/
    const onDigCPMOverrideChange = (e) => {
        var tempRev = 0;
        var tempeCPM = 0;

    
        if (e.target.value != 0) {
            tempRev = digitalRevenue;
            tempeCPM = e.target.value;
            setDigOverride(e.target.value);
            setdigitalPGRcurrent((e.target.value)||0);
        }
        else {
            tempRev = digitalRevenue;
            tempeCPM = digitalPGRold;
            setDigOverride(0);
            setdigitalPGRcurrent(digitalPGRold);
        }
            
            // console.log('tempRev:', tempRev);
            // console.log('tempeCPM', tempeCPM);
            // console.log('ecpmYear1:', ecpmYear1);
            // console.log('digitaleCPMYear1:', digitaleCPMYear1);
            // console.log('digitalRevenue:', digitalRevenue);

            setdigitaleCPMYear1((tempeCPM * ecpmYear1)||0);
            setdigitaleCPMYear2((tempeCPM * ecpmYear2)||0);
            setdigitaleCPMYear3((tempeCPM * ecpmYear3)||0);
            setdigitaleCPMYear4((tempeCPM * ecpmYear4)||0);
            setdigitaleCPMYear5((tempeCPM * ecpmYear5)||0);

            // setdigitalrevenueyear1(digitaleCPMYear1/tempeCPM * digitalRevenue);
            // setdigitalrevenueyear2(digitaleCPMYear2/tempeCPM * digitalRevenue);
            // setdigitalrevenueyear3(digitaleCPMYear3/tempeCPM * digitalRevenue);
            // setdigitalrevenueyear4(digitaleCPMYear4/tempeCPM * digitalRevenue);
            // setdigitalrevenueyear5(digitaleCPMYear5/tempeCPM * digitalRevenue);

            setdigitalrevenueyear1((tempeCPM * ecpmYear1)/(tempRev * 1000 / impressionsdigital) * (tempRev)||0);
            setdigitalrevenueyear2((tempeCPM * ecpmYear2)/(tempRev * 1000 / impressionsdigital) * (tempRev)||0);
            setdigitalrevenueyear3((tempeCPM * ecpmYear3)/(tempRev * 1000 / impressionsdigital) * (tempRev)||0);
            setdigitalrevenueyear4((tempeCPM * ecpmYear4)/(tempRev * 1000 / impressionsdigital) * (tempRev)||0);
            setdigitalrevenueyear5((tempeCPM * ecpmYear5)/(tempRev * 1000 / impressionsdigital) * (tempRev)||0);
        
    }
    
    //broadcast
    const bcasteCPMYear1 = bcastPGRcurrent * ecpmYear1 ||0 //F35
    const bcasteCPMYear2 = bcastPGRcurrent * ecpmYear2 ||0  //H35
    const bcasteCPMYear3 = bcastPGRcurrent * ecpmYear3 ||0  //J35
    const bcasteCPMYear4 = bcastPGRcurrent * ecpmYear4 ||0  //L35
    const bcasteCPMYear5 = bcastPGRcurrent * ecpmYear5 ||0  //N35
    //ddl
    const ddleCPMYear1 = ddlPGRcurrent * ecpmYear1      //F36
    const ddleCPMYear2 = ddlPGRcurrent * ecpmYear2      //H36
    const ddleCPMYear3 = ddlPGRcurrent * ecpmYear3      //J36
    const ddleCPMYear4 = ddlPGRcurrent * ecpmYear4      //L36
    const ddleCPMYear5 = ddlPGRcurrent * ecpmYear5      //N46
    //addressable
    const addreCPMYear1 = addressablePGRcurrent * ecpmYear1 //F37
    const addreCPMYear2 = addressablePGRcurrent * ecpmYear2 //H37
    const addreCPMYear3 = addressablePGRcurrent * ecpmYear3 //J37
    const addreCPMYear4 = addressablePGRcurrent * ecpmYear4 //L37
    const addreCPMYear5 = addressablePGRcurrent * ecpmYear5 //N37
    //dr
    const dreCPMYear1 = drPGRcurrent * ecpmYear1 //F38
    const dreCPMYear2 = drPGRcurrent * ecpmYear2 //H38
    const dreCPMYear3 = drPGRcurrent * ecpmYear3 //J38
    const dreCPMYear4 = drPGRcurrent * ecpmYear4 //L38
    const dreCPMYear5 = drPGRcurrent * ecpmYear5 //N38

    //revenue for slide 3 Project Revenues
    const bcastrevenueyear1 = bcasteCPMYear1/bcastPGRcurrent * broadcastRevenue ||0 ;
    const bcastrevenueyear2 = bcasteCPMYear2/bcastPGRcurrent * broadcastRevenue ||0 ;
    const bcastrevenueyear3 = bcasteCPMYear3/bcastPGRcurrent * broadcastRevenue ||0 ;
    const bcastrevenueyear4 = bcasteCPMYear4/bcastPGRcurrent * broadcastRevenue ||0 ;
    const bcastrevenueyear5 = bcasteCPMYear5/bcastPGRcurrent * broadcastRevenue ||0 ;

    const ddlrevenueyear1 = ddleCPMYear1/ddlPGRcurrent * ddlRevenue ||0 ;
    const ddlrevenueyear2 = ddleCPMYear2/ddlPGRcurrent * ddlRevenue ||0 ;
    const ddlrevenueyear3 = ddleCPMYear3/ddlPGRcurrent * ddlRevenue ||0 ;
    const ddlrevenueyear4 = ddleCPMYear4/ddlPGRcurrent * ddlRevenue ||0 ;
    const ddlrevenueyear5 = ddleCPMYear5/ddlPGRcurrent * ddlRevenue ||0 ;

    const addressablerevenueyear1 = addreCPMYear1/addressablePGRcurrent * addressableRevenue ||0 ;
    const addressablerevenueyear2 = addreCPMYear2/addressablePGRcurrent * addressableRevenue ||0 ;
    const addressablerevenueyear3 = addreCPMYear3/addressablePGRcurrent * addressableRevenue ||0 ;
    const addressablerevenueyear4 = addreCPMYear4/addressablePGRcurrent * addressableRevenue ||0 ;
    const addressablerevenueyear5 = addreCPMYear5/addressablePGRcurrent * addressableRevenue ||0 ;

    const drrevenueyear1 = dreCPMYear1/drPGRcurrent * drRevenue ||0 ;
    const drrevenueyear2 = dreCPMYear2/drPGRcurrent * drRevenue ||0 ;
    const drrevenueyear3 = dreCPMYear3/drPGRcurrent * drRevenue ||0 ;
    const drrevenueyear4 = dreCPMYear4/drPGRcurrent * drRevenue ||0 ;
    const drrevenueyear5 = dreCPMYear5/drPGRcurrent * drRevenue ||0 ;

    const sumRevCurrent = +broadcastRevenue + +ddlRevenue + +addressableRevenue + +drRevenue + +digitalRevenue;
    const sumRevYear1 = +bcastrevenueyear1 + +ddlrevenueyear1 + +addressablerevenueyear1 + +drrevenueyear1 + +digitalrevenueyear1;
    const sumRevYear2 = +bcastrevenueyear2 + +ddlrevenueyear2 + +addressablerevenueyear2 + +drrevenueyear2 + +digitalrevenueyear2;
    const sumRevYear3 = +bcastrevenueyear3 + +ddlrevenueyear3 + +addressablerevenueyear3 + +drrevenueyear3 + +digitalrevenueyear3;
    const sumRevYear4 = +bcastrevenueyear4 + +ddlrevenueyear4 + +addressablerevenueyear4 + +drrevenueyear4 + +digitalrevenueyear4;
    const sumRevYear5 = +bcastrevenueyear5 + +ddlrevenueyear5 + +addressablerevenueyear5 + +drrevenueyear5 + +digitalrevenueyear5;
    
    //slide 5 revenue enhancements:
    const revenuediffyear1 = sumRevYear1 - sumRevCurrent; //(+bcastrevenueyear1 ||0 + +ddlrevenueyear1 ||0 + +addressablerevenueyear1 ||0 + +digitalrevenueyear1 ||0 )-(+broadcastRevenue ||0 + +ddlRevenue ||0 + +addressableRevenue ||0 + +drRevenue ||0 + +digitalRevenue ||0) ||0; 
    const revenuediffyear2 = sumRevYear2 - sumRevYear1;//(+bcastrevenueyear2 ||0+ +ddlrevenueyear2 ||0+ +addressablerevenueyear2 ||0 + +drrevenueyear2 ||0  + +digitalrevenueyear2 ||0 )-(+bcastrevenueyear1 ||0  + +ddlrevenueyear1 ||0  + +addressablerevenueyear1 ||0  + +drrevenueyear1 ||0  + +digitalrevenueyear1 ||0 ) ||0 ;
    const revenuediffyear3 = sumRevYear3 - sumRevYear2;//(+bcastrevenueyear3 ||0+ +ddlrevenueyear3 ||0+ +addressablerevenueyear3 ||0  + +drrevenueyear3 ||0  + +digitalrevenueyear3 ||0 )-(+bcastrevenueyear2 ||0  + +ddlrevenueyear2 ||0  + +addressablerevenueyear2 ||0  + +drrevenueyear2 ||0  + +digitalrevenueyear2 ||0 ) ||0 ;
    const revenuediffyear4 = sumRevYear4 - sumRevYear3;//(+bcastrevenueyear4 ||0+ +ddlrevenueyear4 ||0+ +addressablerevenueyear4 ||0  + +drrevenueyear4 ||0  + +digitalrevenueyear4 ||0 )-(+bcastrevenueyear3 ||0  + +ddlrevenueyear3 ||0  + +addressablerevenueyear3 ||0  + +drrevenueyear3 ||0  + +digitalrevenueyear3 ||0 ) ||0 ;
    const revenuediffyear5 = sumRevYear5 - sumRevYear4;//(+bcastrevenueyear5 ||0+ +ddlrevenueyear5 ||0+ +addressablerevenueyear5 ||0  + +drrevenueyear5 ||0  + +digitalrevenueyear5 ||0 )-(+bcastrevenueyear4 ||0  + +ddlrevenueyear4 ||0  + +addressablerevenueyear4 ||0  + +drrevenueyear4 ||0  + +digitalrevenueyear4 ||0 ) ||0 ;
    //   console.log('sumRevCyrrent:', sumRevCurrent);
    //   console.log('sumRevYear1:', sumRevYear1);
    //  console.log('broadcastrev:', broadcastRevenue);
    //   console.log('ddlrev:', ddlRevenue);
    //   console.log('addressablerevenueyear1:', addressablerevenueyear1);
    //   console.log('drrevenueyear1:', drrevenueyear1);
    //   console.log('digitalrevenueyear1:', digitalrevenueyear1);
    //   console.log('revdifyear1:', revenuediffyear1);
    //   console.log('revdifyear2:', revenuediffyear2);
    
     //Inventory Clearance 
    const observedinventoryclearance = .22;
    const inventoryclearanceyear1 = revenuediffyear1 * observedinventoryclearance/5;
    const inventoryclearanceyear2 = revenuediffyear2  * observedinventoryclearance/5;
    const inventoryclearanceyear3 = revenuediffyear3  * observedinventoryclearance/5;
    const inventoryclearanceyear4 = revenuediffyear4  * observedinventoryclearance/5;
    const inventoryclearanceyear5 = revenuediffyear5  * observedinventoryclearance/5;
    
    //reduced overdelivery
    const observedover = .35;
    const overyear1 = revenuediffyear1 * observedover/5;    
    const overyear2 = revenuediffyear2 * observedover/5;
    const overyear3 = revenuediffyear3 * observedover/5;
    const overyear4 = revenuediffyear4 * observedover/5;
    const overyear5 = revenuediffyear5 * observedover/5;

    //reduced under delivery
    const observedunder = .25;
    const underyear1 = revenuediffyear1 * observedunder/5;
    const underyear2 = revenuediffyear2 * observedunder/5;
    const underyear3 = revenuediffyear3 * observedunder/5;
    const underyear4 = revenuediffyear4 * observedunder/5;
    const underyear5 = revenuediffyear5 * observedunder/5;

    //intelligent allocations
    const intelligentallocations = .23;
    const intallyear1 = revenuediffyear1 * intelligentallocations/5;
    const intallyear2 = revenuediffyear2 * intelligentallocations/5;
    const intallyear3 = revenuediffyear3 * intelligentallocations/5;
    const intallyear4 = revenuediffyear4 * intelligentallocations/5;
    const intallyear5 = revenuediffyear5 * intelligentallocations/5;

    //slide 6: revenue enhancements #2
    //increase in eCPM
    const eCPM = .35;
    const eCPMyear1 = revenuediffyear1 * eCPM ||0 ;
    const eCPMyear2 = revenuediffyear2 * eCPM ||0 ;
    const eCPMyear3 = revenuediffyear3 * eCPM ||0 ;
    const eCPMyear4 = revenuediffyear4 * eCPM ||0 ;
    const eCPMyear5 = revenuediffyear5 * eCPM ||0 ;

    //improved pricing
    const price = .17;
    const priceyear1 = revenuediffyear1 * price;
    const priceyear2 = revenuediffyear2 * price;
    const priceyear3 = revenuediffyear3 * price;
    const priceyear4 = revenuediffyear4 * price;
    const priceyear5 = revenuediffyear5 * price;

    //pacing

    //reporting time
    //adops salary
    const salaryyear1 = totalSalary * 1.039;
    const salaryyear2 = salaryyear1 * 1.039;
    const salaryyear3 = salaryyear2 * 1.039;
    const salaryyear4 = salaryyear3 * 1.039;
    const salaryyear5 = salaryyear4 * 1.039;

    const timereduce = .75;
    const timereduceyear1 = salaryyear1/4 * timereduce ||0 ;
    const timereduceyear2 = salaryyear2/4 * timereduce ||0 ;
    const timereduceyear3 = salaryyear3/4 * timereduce ||0 ;
    const timereduceyear4 = salaryyear4/4 * timereduce ||0 ;
    const timereduceyear5 = salaryyear5/4 * timereduce ||0 ;

    const pacing = .35;
    const pacingyear1 = salaryyear1 * pacing ||0 ;
    const pacingyear2 = salaryyear2 * pacing ||0 ;
    const pacingyear3 = salaryyear3 * pacing ||0 ;
    const pacingyear4 = salaryyear4 * pacing ||0 ;
    const pacingyear5 = salaryyear5 * pacing ||0 ;

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',

    };

    const indicatorStyles = {
        background: '#1C8EFF',
        width: 18,
        height: 12,
        display: 'inline-block',
        margin: '0 12px',
    };

    // Button Text
    const [buttonText, setButtonText] = useState('Save');

    // post a msg to the API
    async function SendMessage(strEmail,numAMPH,numAVMH,numMapChannels,numStations,numTotalSalary
        ,numBroadcastImpressions,numDDLImpressions,numAddressableImpressions
        ,numDigitalImpressions,numBroadcastRevenue,numDDLRevenue,numAddressableRevenue
        ,numDRRevenue,numDigitalRevenue,numDigitaleCPMOverride,strFirstName,strLastName
        ,strCompany,strPosition,strPhoneNumber) {
        
        let data = null
        try{
            if (strEmail == undefined){strEmail='n/a';}
            if (numAMPH == undefined){numAMPH=0;}
            if (numAVMH == undefined){numAVMH=0;}
            if (numMapChannels == undefined){numMapChannels=0;}
            if (numStations == undefined){numStations=0;}
            if (numTotalSalary == undefined){numTotalSalary=0;}
            if (numBroadcastImpressions == undefined){numBroadcastImpressions=0;}
            if (numDDLImpressions == undefined){numDDLImpressions=0;}
            if (numAddressableImpressions == undefined){numAddressableImpressions=0;}
            if (numDigitalImpressions == undefined){numDigitalImpressions=0;}
            if (numBroadcastRevenue == undefined){numBroadcastRevenue=0;}
            if (numDDLRevenue == undefined){numDDLRevenue=0;}
            if (numAddressableRevenue == undefined){numAddressableRevenue=0;}
            if (numDRRevenue == undefined){numDRRevenue=0;}
            if (numDigitalRevenue == undefined){numDigitalRevenue=0;}
            if (numDigitaleCPMOverride == undefined){numDigitaleCPMOverride=0;}
            if (strFirstName == undefined){strFirstName='n/a';}
            if (strLastName == undefined){strLastName='n/a';}
            if (strCompany == undefined){strCompany='n/a'}
            if (strPosition == undefined){strPosition='n/a';}
            if (strPhoneNumber == undefined){strPhoneNumber='n/a';}

            const response = await fetch('https://kvprk7ia83.execute-api.us-east-1.amazonaws.com/test/ROICalcManager', {
                            mode: 'no-cors',
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                                    "operation": "insert",
                                    "payload": {
                                        "email_address": strEmail,
                                        "amph": numAMPH,
                                        "avmh": numAVMH,     
                                        "mapChannels": numMapChannels,
                                        "stations": numStations,
                                        "totalSalary": numTotalSalary,                               
                                        "broadcastImpressions": numBroadcastImpressions,
                                        "ddlImpressions": numDDLImpressions,
                                        "addressableImpressions": numAddressableImpressions,
                                        "digitalImpressions": numDigitalImpressions,
                                        "broadcastRevenue": numBroadcastRevenue,
                                        "ddlRevenue": numDDLRevenue,
                                        "addressableRevenue": numAddressableRevenue,
                                        "drRevenue": numDRRevenue,
                                        "digitalRevenue": numDigitalRevenue,
                                        "digitaleCPMOverride": numDigitaleCPMOverride,
                                        "first_name": strFirstName,
                                        "last_name": strLastName,
                                        "company": strCompany,
                                        "position": strPosition,
                                        "phone_number": strPhoneNumber
                                    }
                                }
                            ),
                        });
                    //data = response.json();
                    //console.log(data.stringify());
                    //setButtonText(data); 
        } catch(err)  {
            console.error(err);
        }
        
        try{
            const emailresponse = await fetch('https://ls2uj08a3d.execute-api.us-east-1.amazonaws.com/default/ROICalcSendEmail', {
                            mode: 'no-cors',
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                                    "senderName": strFirstName + " " + strLastName,
                                    "senderEmail": strEmail,
                                    "message": `<h4>ROI Calc Submitted by: ${strFirstName} ${strLastName}</h4> <ul style="margin:0; margin-left: 25px; padding:0; font-family: Arial, sans-serif; color:#495055; font-size:16px; line-height:22px;" align="left" type="disc">
                                    <li>Email: ${strEmail}</li>
                                    <li>Company: ${strCompany}</li>
                                    <li>Position: ${strPosition}</li>
                                    <li>Phone: ${strPhoneNumber}</li></ul>
                                    Data:
                                    <ul style="margin:0; margin-left: 25px; padding:0; font-family: Arial, sans-serif; color:#495055; font-size:16px; line-height:22px;" align="left" type="disc">
                                    <li>AMPH: ${numAMPH}</li>
                                    <li>AMVH: ${numAVMH}</li>
                                    <li>Num Channels: ${numMapChannels}</li>
                                    <li>Num Stations: ${numStations}</li>
                                    <li>Total Salary: ${numTotalSalary}</li>
                                    <li>Broadcast Impressions: ${numBroadcastImpressions}</li>
                                    <li>DDL Impressions: ${numDDLImpressions}</li>
                                    <li>Addressable Impressions: ${numAddressableImpressions}</li>
                                    <li>Digital Impressions: ${numDigitalImpressions}</li>
                                    <li>Broadcast Revenue: ${numBroadcastRevenue}</li>
                                    <li>DDL Revenue: ${numDDLRevenue}</li>
                                    <li>Addressable Revenue: ${numAddressableRevenue}</li>
                                    <li>DR Revenue: ${numDRRevenue}</li>
                                    <li>Digitial Revenue: ${numDigitalRevenue}</li>
                                    <li>Digital CPM Override: ${numDigitaleCPMOverride}</li>
                                    </ul>`
                                }
                            ),
                        });
                    //data = response.json();
                    //console.log(data.stringify());
                    //setButtonText(data); 
        } catch(err)  {
            console.error(err);
        }
        
    }
    const onBtnClick=() => {
        SendMessage(contactEmail,amph, avmh, numMapChannels, numStations, totalSalary, broadcastImpressions, ddlImpressions, addressableImpressions, digitalImpressions,broadcastRevenue,ddlRevenue,addressableRevenue,drRevenue,digitalRevenue,digitaleCPMOverride, contactFirstName, contactLastName,contactCompany,contactPosition,contactPhone);
        setButtonText("Data Saved");
        //window.location.reload(false);
        setisBtnDisabled(false);
    };

    //handle any of the checkboxes get checked for TOS
    const checkboxHandler = changeEvent => {
        if (changeEvent.target.id == "CB1"){
            setCB1(!cb1);
        }
        if (changeEvent.target.id == "CB2"){
            setCB2(!cb2);
        }
        if (changeEvent.target.id == "CB3"){
            setCB3(!cb3);
        }
        if (changeEvent.target.id == "CB4"){
            setCB4(!cb4);
        }
      }
    
    // When the continue button is clicked, show the rest of the app, hide TOS
    const btnHandler = () => {
        setAgree(!agree);
    };

    return (
<> 
{/* TOS */}
    {!agree && (
        
    <div className="TOS">


        <div className="row">
            <div className="col-md-12"><p><img src="./BIAnalytixLogo.png"></img></p></div>
        </div>
    
        <div className="d-grid gap-2">
            <div className="row">
                <div className="col-md-12">
                <label htmlFor="agree"><b>Agreement</b> </label>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label htmlFor="agree">To proceed with the ROI Analysis, please respond to the following:
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col-md-1 text-end">
                    <input type="checkbox" id="CB1" onChange={checkboxHandler} />
                </div>
                <div className="col-md-11">    
                    <label htmlFor="agree">This ROI Calculator is for information purposes only. The estimates provided are believed to be reliable, yet indicative only. They are  based upon Decentrix case studies and experience of same. Decentrix does not warrant their completeness or accuracy as a specific business environment may vary from our projections. The information and materials contained in these pages, and the descriptions that appear in the analysis are subject to change without notice. 
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1 text-end">
                    <input type="checkbox" id="CB2" onChange={checkboxHandler} />
                </div>
                <div className="col-md-11"> 
                    <label htmlFor="agree">I agree to having my contact details and summary business profile data stored by Decentrix for the explicit use of sharing with me this ROI analysis and about sales processes in relation to BIAnalytix™.
                    </label>
                </div>
            </div>
            <div className="row">   
                <div className="col-md-1 text-end">
                    <input type="checkbox" id="CB3" onChange={checkboxHandler} />
                </div>
                <div className="col-md-11">
                    <label htmlFor="agree">I understand that information provided by me will not be sold, nor will it be shared with anyone but Decentrix employees for the intended purpose of estimating an ROI for BIAnalytix™ as it could potentially apply to my business.
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1 text-end">
                    <input type="checkbox" id="CB4" onChange={checkboxHandler} />
                </div>
                <div className="col-md-11"> 
                    <label htmlFor="agree">I understand that I can request to have the provided information removed from Decentrix’s data repository at any time by sending a request via email to ROICalcSupport@decentrix.net
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-1">
                    <button disabled={(!cb1 || !cb2 || !cb3 || !cb4)} className="btn btn-primary" onClick={btnHandler}>
                    Continue
                    </button>
                </div>
            </div>
        </div>

    </div>

    )}

{/*Rest of app */}
    {agree && (
        <div className="carousel-wrapper">
            <div class="container">
                <div class="row"><div class="col"><label>&nbsp;</label></div></div>
                <div class="row"><div class="col"><p><img src="./BIAnalytixLogo.png"></img></p></div>
            </div>
        <Wrapper>

            <Carousel
                showThumbs={false}
                showIndicators={!isdisabled}
                statusFormatter={(current, total) => `${current-1} of ${total-1}`}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 0 }}>
                            -
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button type="button" disabled={isdisabled} onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}>
                            +
                        </button>
                    )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => { 
                    if (isSelected) {
                        return ( 
                            <li
                                style={{ ...indicatorStyles, background: '#000' }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                            );
                        }
                        return (
                            <li
                                style={indicatorStyles}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                title={`${label} ${index + 1}`}
                                aria-label={`${label} ${index + 1}`}
                            />
                        );
                    }}
            >
            
                    {/* page 0 */}
                    <div class="container">
                        <div class="row">
                            <h2 class="text-start text-primary">ROI Calculator</h2>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-8">
                            <h5 class="text-start text-muted">Welcome</h5>
                                <p class="text-start ">Return On Investment (ROI) is the primary economic value proposition for any enterprise software solution. Decentrix has developed this tool to calculate a basic, conservative ROI for BIAnalytix™.</p>
                                <p class="text-start">This is based upon fundamental business assumptions along with metrics and research collected from a wide catalog of case studies representative of media workflows.</p>
                                <p class="text-start text-primary">Please enter your email to proceed (required):</p>
                                <div class ="col-6"><p><input type="email" tabindex="-1" value={contactEmail} onChange={onEmailChange} id="email" placeholder="email address" class="text-lowercase form-control text-start" /></p> 
                                </div>
                                
                            </div>
                                <div class="col">
                                    <div class="row"><p class="text-start text-primary">1: Business Parameters</p></div>
                                    <div class="row"><p class="text-start text-primary">2: Viewership & Impressions</p></div>
                                    <div class="row"><p class="text-start text-primary">3: Project Revenues</p></div>
                                    <div class="row"><p class="text-start text-primary">4: Projected Growth Rate</p></div>
                                    <div class="row"><p class="text-start text-primary">5: Revenue Enhancements - 1/2</p></div>
                                    <div class="row"><p class="text-start text-primary">6: Revenue Enhancements - 2/2</p></div>
                                    <div class="row"><p class="text-start text-primary">7: Next Steps</p></div>
                                </div>
                            </div>
                    </div>

                    {/* page 1 */}
                    <div class="d-grid gap-2">
                        <div class="row">
                            <div class="col-12"><h2 class="text-start text-primary">1: Business Parameters</h2></div>
                        </div>

                        <div class="row"> <p></p></div>
                        <div class="row">

                        <div class="col-1"></div>
                            <div class="col-11"><h5 class="text-start text-muted">Advertising Engagement Metrics</h5></div>
                        </div>

                        <div class="row">
                        <div class="col-1"></div>
                            <div class="col-5 text-end"><label>Advertising Minutes per Hour:</label></div>
                            <div class="col-4 text-end">
                                <div class="form-outline">
                                    <Tippy content="Advertising Minutes per Hour - the maximum number of minutes of advertising availability for the market.">
                                        <input type="number" tabindex="-1" value={amph} class="form-control" onChange={(e) => setAMPH(e.target.value)} />
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-5 text-end"><label>Average Viewing Minutes per Hour:</label></div>
                            <div class="col-4 text-end">
                                <div class="form-outline">
                                    <Tippy content="Average Viewing Time per Hour - the average number of minutes per hour that a consumer watches of video, regardless of the delivery medium.">
                                        <input type="number" tabindex="-1" value={avmh} class="form-control" onChange={(e) => setAVMH(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                        </div>

                        <div class="row">&nbsp;</div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11"><h5 class="text-start text-muted">Advertising Operational Costs </h5></div>
                        </div>


                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-5 text-end"><label>Number of Channels Being Managed:</label></div>
                            <div class="col-4 text-end">
                                <div class="form-outline">
                                    <Tippy content="Number of Channels Being Managed - the number of schedules/logs/channels being managed with the current operational costs.">
                                        <input type="number" tabindex="-1" value={numMapChannels} class="form-control" onChange={(e) => setNumMapChannels(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                        </div>
                       
                        <div class="row">
                        <   div class="col-1"></div>
                            <div class="col-5 text-end"><label>Number of Stations Being Managed:</label></div>
                            <div class="col-4 text-end">
                                <div class="form-outline">
                                    <Tippy content="Number of Stations Being Managed - the number of stations being managed with the current operational costs.">
                                        <input type="number" tabindex="-1" value={numStations} class="form-control" onChange={(e) => setNumStations(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-5 text-end align-items-center">Total Salary of AdOps Personnel ($'000 per Year):</div>
                            <div class="col-4 text-end align-items-center ">
                                <div class="form-outline"> 
                                    <Tippy content="AdOps Salaries - the total salary cost of all ad operations staff in the organization's advertising supply chain - from pricing to billing.">
                                        <input type="number" tabindex="-1" value={totalSalary} class="form-control" onChange={(e) => setTotalSalary(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12"> <h2 class="text-start text-primary">&nbsp;</h2></div>
                        </div>
                        <div class="row">
                            <div class="col-md-12"> <h2 class="text-start text-primary">&nbsp;</h2></div>
                        </div>
                    </div>

                    {/* page 2 */}
                    <div class="d-grid gap-2">
                        <div class="row">
                            <div class="col-12"><h2 class="text-start text-primary">2: Viewership & Impressions</h2></div>
                        </div>

                        <div class="row"> <p></p></div>
                       
                        <div class="row">
                            <div class="col-12 text-start">
                                
                                <div class="row">
                                        <div class="col-1"></div>
                                        <div class="col-5"><h5 class="text-start text-muted">Viewership ('000s)</h5></div>
                                        <div class="col-1"></div>
                                        <div class="col-5"><h5 class="text-start text-muted">Impressions ('000s)</h5></div>
                                </div>                 
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-3 text-end"><label>Broadcast:</label></div>
                            <div class="col-2 text-start">
                                <div class="form-outline">
                                    <Tippy content="Linear Broadcast - number of broadcast TV viewers in the marketplace.">
                                        <input type="number" tabindex="-1" value={broadcastImpressions} class="form-control" onChange={(e) => setBroadcastImpressions(e.target.value)} />
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>

                            <div class="col-5 text-end">
                                <label class="text-primary">{impressionsbroadcast.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</label>
                            </div>
                            <div class="col-1"></div>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-3 text-end"><label>Data Driven Linear (DDL):</label></div>
                            <div class="col-2 text-start">
                                <div class="form-outline">
                                    <Tippy content="Linear DDL – a subset of the total number of linear broadcast viewers – those targeted by campaigns sold with guaranteed ratings for specific target segments">
                                        <input type="number" tabindex="-1" value={ddlImpressions} class="form-control" onChange={(e) => setDDLImpressions(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>

                            <div class="col-5 text-end">
                                <label class="text-primary">{impressionsddl.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</label>
                            </div>
                            <div class="col-1"></div>

                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-3 text-end"><label>Addressable:</label></div>
                            <div class="col-2 text-start">
                                <div class="form-outline">
                                    <Tippy content="Linear Addressable – the number of addressable enabled devices that can be directly counted based upon subscriptions without overlap with both linear broadcast and linear DDL.">
                                        <input type="number" tabindex="-1" value={addressableImpressions} class="form-control" onChange={(e) => setAddressableImpressions(e.target.value)}/>
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>

                            <div class="col-5 text-end">
                                <label class="text-primary">{impressionsaddressable.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</label>
                            </div>
                            <div class="col-1"></div>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-3 text-end"><label>Digital:</label></div>
                            <div class="col-2 text-start">
                                <div class="form-outline"> {/*onChange={(e) => setDigitalImpressions(e.target.value)}/>*/}
                                    <Tippy content="Digital – the count of unique individual persons determined from viewership data logs of video/audio content. ">
                                        <input type="number" tabindex="-1" value={digitalImpressions} class="form-control" onChange={(e) => setDigitalImpressions(e.target.value)}/> 
                                    </Tippy>
                                    {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>

                            <div class="col-5 text-end">
                                <label class="text-primary">{impressionsdigital.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</label>
                            </div>
                            <div class="col-1"></div>

                        </div>
                    </div>
{/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ page 3 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
                    <div class="d-grid gap-2">
                        <div class="row">
                            <div class="col-12"><h2 class="text-start text-primary">3: Projected Revenues</h2></div>
                        </div>

                        <div class="row"> <p></p></div>

                        <div class="row">
                            <div class="col-3">
                                    <p class="text-start">&nbsp;</p>
                            </div>
                            <div class="col-2">
                                    <label class="text-">Current:</label>
                            </div>
                            <div class="col-1">
                                    <label class="text-">Year1:</label>
                            </div>
                            <div class="col-1">
                                    <label class="text-">Year2:</label>
                            </div>
                            <div class="col-1">
                                    <label class="text-">Year3:</label>
                            </div>
                            <div class="col-1">
                                    <label class="text-">Year4:</label>
                            </div>
                            <div class="col-1">
                                    <label class="text-">Year5:</label>
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="col-3 text-end">
                                <label class="text-start">Broadcast:</label>
                            </div>
                            <div class="col-2">
                                <div class="form-outline">
                                        <Tippy content="Linear Broadcast – actual billed revenue for linear broadcast video/audio.">
                                             <input type="number" tabindex="-1" value={broadcastRevenue} class="form-control"  onChange={(e) => setBroadcastRevenue(e.target.value)} />
                                        </Tippy>
                                            {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastrevenueyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastrevenueyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastrevenueyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastrevenueyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastrevenueyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-end">DDL:</label>

                            </div>
                            <div class="col-2">
                                <div class="form-outline">
                                        <Tippy content="Linear DDL – actual billed revenue for linear DDL video/audio ads which a narrowcasted target segment of the viewing audience sees.">
                                            <input type="number" tabindex="-1" value={ddlRevenue} class="form-control"  onChange={(e) => setDDLRevenue(e.target.value)} />
                                        </Tippy>
                                            {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlrevenueyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlrevenueyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlrevenueyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlrevenueyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlrevenueyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-end">Addressable:</label>

                            </div>
                            <div class="col-2">
                                <div class="form-outline">
                                        <Tippy content="Linear Addressable – actual billed revenue for linear addressable video/audio.">
                                            <input type="number" tabindex="-1" value={addressableRevenue} class="form-control"  onChange={(e) => setAddressableRevenue(e.target.value)} />
                                        </Tippy>
                                            {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablerevenueyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablerevenueyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablerevenueyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablerevenueyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablerevenueyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-end">DR:</label>

                            </div>
                            <div class="col-2">
                                <div class="form-outline">
                                        <Tippy content="Linear DR - actual billed revenue for linear DR video/audio.">
                                            <input type="number" tabindex="-1" value={drRevenue} class="form-control" onChange={(e) => setDRRevenue(e.target.value)} />
                                        </Tippy>
                                            {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{drrevenueyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{drrevenueyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{drrevenueyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{drrevenueyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{drrevenueyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-end">Digital:</label>

                            </div>
                            <div class="col-2">
                                <div class="form-outline">
                                        <Tippy content="Digital - actual billed revenue for digital video/audio. Note that we are only focused on time-domain advertising.">
                                            <input type="number" tabindex="-1" value={digitalRevenue} class="form-control" onChange={onDigRevChange}/>
                                        </Tippy>
                                            {/* <label class="form-label" for="form12"></label> */}
                                </div>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalrevenueyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalrevenueyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalrevenueyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalrevenueyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalrevenueyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD",maximumFractionDigits: 0 })}</label></div>
                        </div>
                    </div>

{/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ page 4 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
                    <div class="d-grid gap-3">
                        <div class="row">
                            <h2 class="text-start text-primary">4: Projected Growth of Ad Rates</h2>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11"><h5 class="text-start text-muted">Advertising Rates (eCPM)</h5></div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                    <label class="text-start">&nbsp;</label>

                            </div>
                            <div class="col-1">
                                    <label>Current:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year1:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year2:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year3:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year4:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year5:</label>

                            </div>

                        </div>

                        {/* eCPM Broadcast */}
                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">Broadcast:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{bcastPGRcurrent.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcasteCPMYear1.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcasteCPMYear2.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcasteCPMYear3.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcasteCPMYear4.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{bcasteCPMYear5.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                        </div>

                        {/* eCPM DDL */}
                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">DDL:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{ddlPGRcurrent.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddleCPMYear1.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddleCPMYear2.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddleCPMYear3.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddleCPMYear4.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{ddleCPMYear5.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                        </div>

                        {/* eCPM Addressable */}
                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">Addressable:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{addressablePGRcurrent.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addreCPMYear1.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addreCPMYear2.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addreCPMYear3.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addreCPMYear4.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{addreCPMYear5.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                        </div>
                        {/* eCPM DR */}
                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">DR:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{drPGRcurrent.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{dreCPMYear1.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{dreCPMYear2.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{dreCPMYear3.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{dreCPMYear4.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{dreCPMYear5.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                        </div>
                        {/* eCPM Digital */}
                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">Digital:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{digitalPGRcurrent.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitaleCPMYear1.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitaleCPMYear2.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitaleCPMYear3.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitaleCPMYear4.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{digitaleCPMYear5.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-3 text-end">
                                <label class="text-start">Actual Digital eCPM:</label>
                            </div>
                            <div class="col-1 text-end">
                                <Tippy content="Actual Digital eCPM – the actual digital eCPM from your systems, this will override the Current calculated value above.">
                                    <input type="number" tabindex="-1" class="form-control text-primary" value={digitaleCPMOverride} onChange={onDigCPMOverrideChange}></input>
                                </Tippy>
                            </div>  
                        </div>

                    </div>

{/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ page 5  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
                    <div class="d-grid gap-3">
                        <div class="row">
                            <h2 class="text-start text-primary">5: Projected Revenue Enhancements - 1/2</h2>
                        </div>

                        <div class="row">
                           
                            <div class="col-1">
                                    <label class="text-"></label>
                            </div>

                            <div class="col-3">
                                    <h5 class="text-start text-muted">Inventory Management</h5>
                            </div>

                            {/*  */}

                            <div class="col-1">
                                    <label class="text-">Year1:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year2:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year3:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year4:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year5:</label>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Enabling intelligent allocations:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{intallyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{intallyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{intallyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{intallyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{intallyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Reduced under-delivery:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{underyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{underyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{underyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{underyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{underyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Reduced over-delivery:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{overyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{overyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{overyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{overyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{overyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                      
                        </div>

                        <div class="row">
                        <div class="col-1"></div>
                            <div class="col-md-11"><h5 class="text-start text-muted">Campaign Delivery</h5></div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Inventory clearance:</label>
                            </div>
                            <div class="col-1 text-end"><label class="text-primary">{inventoryclearanceyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{inventoryclearanceyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{inventoryclearanceyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{inventoryclearanceyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{inventoryclearanceyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                        </div>
                    </div>

{/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ page 6 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
                    <div class="d-grid gap-3">
                        <div class="row">
                            <h2 class="text-start text-primary">6: Projected Revenue Enhancements - 2/2</h2>
                        </div>

                        <div class="row">
                            <div class="col-1">
                                    <label class="text-"></label>
                            </div>

                            <div class="col-3">
                                <h5 class="text-start text-muted">Media Operations</h5>

                            </div>

                            <div class="col-1">
                                    <label >Year1:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year2:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year3:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year4:</label>

                            </div>
                            <div class="col-1">
                                    <label class="text-">Year5:</label>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Pacing operations improvement:</label>
                            </div>

                            <div class="col-1 text-end"><label class="text-primary">{pacingyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{pacingyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{pacingyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{pacingyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{pacingyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
        
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Reduced campaign reporting times:</label>
                            </div>

                            <div class="col-1 text-end"><label class="text-primary">{timereduceyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{timereduceyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{timereduceyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{timereduceyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{timereduceyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
        
                        </div>

                        <div class="row">
                        <div class="col-md-1"></div>
                            <div class="col-md-11"><h5 class="text-start text-muted">Pricing</h5></div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Improved pricing effectiveness:</label>
                            </div>

                            <div class="col-1 text-end"><label class="text-primary">{priceyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{priceyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{priceyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{priceyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{priceyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
        
                        </div>

                        <div class="row">
                            <div class="col-4 text-end">
                                <label class="text-start">Increase in eCPMs:</label>
                            </div>

                            <div class="col-1 text-end"><label class="text-primary">{eCPMyear1.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{eCPMyear2.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{eCPMyear3.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{eCPMyear4.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
                            <div class="col-1 text-end"><label class="text-primary">{eCPMyear5.toLocaleString(navigator.language, { style:"currency", currency:"USD", maximumFractionDigits: 0})}</label></div>
        
                        </div>
                    </div>

{/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ page 7 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
                    <div class="container">
                        <div class="row">
                            <h2 class="text-start text-primary">Next Steps</h2>
                        </div>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-6">
                                <p class="text-start "><h4>Thank You. Your effort will be rewarded.</h4></p>
                                <p class="text-start">1. Decentrix will analyze this data in strict confidence.</p>
                                <p class="text-start">2. We will estimate the cost of a solution and have a representative contact you within 24 hours.</p>
                                <p class="text-start">3. We will provide you with a costed ROI analysis.</p>
                                <p class="text-start">4. Decentrix will share a business presentation that you can take to your executive team for consideration.</p>
                            </div>
                                <div class="col">

                                    <div class="row">
                                         {/* <form onValuesChange = {onContactsChanged}>  */}
                                            <div class="form-horizontal">
                                                <div class="row text-start" >
                                                    <label class="control-label text-primary" for="form12">First Name*:</label>
                                                </div>

                                                <Tippy content="Your first name.">
                                                    <input type="text" tabindex="-1" value={contactFirstName} class="form-control" onChange={(e) => setContactFirstName(e.target.value)}/> 
                                                </Tippy>
                                                

                                                <div class="row text-start" >
                                                    <label class="control-label text-primary" for="form12">Last Name*:</label>
                                                </div>

                                                <Tippy content="Your last name.">
                                                    <input type="text" tabindex="-1" value={contactLastName} class="form-control" onChange={(e) => setContactLastName(e.target.value)}/> 
                                                </Tippy>

                                                <div class="row text-start" >
                                                    <label class="control-label text-primary" for="form12">Company*:</label>
                                                </div>

                                                <Tippy content="Your company's name.">
                                                    <input type="text" tabindex="-1" value={contactCompany} class="form-control" onChange={(e) => setContactCompany(e.target.value)}/> 
                                                </Tippy>

                                                <div class="row text-start" >
                                                    <label class="control-label text-primary" for="form12">Position*:</label>
                                                </div>

                                                <Tippy content="Your position in your company.">
                                                    <input type="text" tabindex="-1" value={contactPosition} class="form-control" onChange={(e) => setContactPosition(e.target.value)}/> 
                                                </Tippy>

                                                <div class="row text-start" >
                                                    <label class="control-label text-primary" for="form12">Phone Number*:</label>
                                                </div>

                                                <Tippy content="Your phone number.">
                                                    <input type="text" tabindex="-1" value={contactPhone} class="form-control" onChange={(e) => setContactPhone(e.target.value)}/> 
                                                </Tippy>

                                            </div>

                                            {/* <button type="submit" class="btn btn-primary">Submit</button> */}

                                            {/* <Tippy content="We'll never share your infomation with anyone else."> */}
                                            <div class="row">
                                                &nbsp;
                                            <p> <button class="btn btn-primary" tabindex="-1" disabled={(!isBtnDisabled || !contactFirstName || !contactLastName || !contactCompany || !contactPosition || !contactPhone)} onClick={onBtnClick} >{buttonText}</button></p>
                                            </div>
                                            {/* </Tippy> */}
                                         {/* </form>  */}
                                    </div>
                                </div>
                            </div>
                    </div>
            </Carousel>
        </Wrapper>
        </div>
    </div>
    )}
    </>
    );
}