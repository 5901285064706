import './App.css';
import { CarouselComponent}  from "./components/carousel.component";


export function App() {
  return (
    <>

     <div className="App">
         <div className='container'>
           <CarouselComponent />
         </div>
     </div> 
    </>
  );
}


